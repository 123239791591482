<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog.png`)" alt="blog" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
              				Platforma Srpski Istraživači
						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/avatar.png`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Serbian Researchers<br>
                  Jun 28, 2021
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							Srbiju svake godine napusti na hiljade talentovanih ljudi, među kojima je veliki broj onih koji se bave,
							ili će se baviti, naučno istraživačkim radom na vrhunskom nivou. Vremenom, postaju vodeći
							stručnjaci u svojim oblastima i pomeraju granice nauke, često kao profesori, šefovi katedri, direktori
							razvoja, osnivači startapova.
							<br><br>
							Međutim, najveći broj njih u potpunosti izgubi vezu sa naučno-istraživačkim institucijama i
							kompanijama u Srbiji. Osim što nemaju kontakt sa kolegama i koleginicama u Srbiji unutar iste
							oblasti kojom se bave, neretko ne poznaju ni istraživače srpskog porekla u gradu u kome trenutno
							borave. Takođe, istraživači u Srbiji nemaju informacije o svojim kolegama u inostranstvu, dok
							srednjoškolci ili diplomci, ne znaju kome bi mogli da se obrate za savet pri želji da nastave
							usavršavanje na najprestižnijim univerzitetima u svetu.
							<br><br>
							Ne postoji jedinstvena, javno dostupna baza podataka, koja bi omogućila pretragu po ključnim
							pojmovima, oblastima ili državi. Ova činjenica otežava upoznavanja, saradnje, zaposlenje i na kraju,
							povratak nazad u Srbiju.

						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							Kreiranje sveobuhvatne baze
						</h3>
						<p class="text_xl  my-3">
							Imajući u vidu sve gore navedeno, pokrećemo ovaj sajt, sa primarnim ciljem da okupimo sve
							istraživače na jednom mestu. Nakon toga, uslediće konkretni koraci kako bismo povezali istraživače
							iz akademije i industrije, koji su zaposleni na katedrama univerziteta, razvojno-istraživačkim centrima
							unutar kompanija, naučnim institutima ili su nezavisni istraživači.
							<br><br>
							Kreiranje profila na platformi, kao i pretraživanje sveobuhvatne baze istraživača je potpuno
							besplatno, dok podatke o članovima zajednice ne delimo, niti na bilo koji način zarađujemo od
							prikupljenih podataka na platformi. Od članova se jedino očekuje da održavaju svoj profil ažurnim i
							da ukoliko su u mogućnosti, izdvoje vreme za druge članove koji ih kontaktiraju. Nadamo se da
							će svojim prisustvom i zalaganjem postati svojevrsni ambasadori organizacije i time doprineti daljem
							razvoju srpske naučne zajednice, u čemu ćemo se truditi da im maksimalno pomognemo.
							<br><br>
							Postepeno, gradićemo ovaj blog kroz pisanje članaka o našim istraživačima, novostima, nagradama,
							uspešnim projektima i ostvarenim saradnjama. U narednim objavama, biće više reči o sledećim
							koracima i novim funkcionalnostima koje ćemo vremenom dodavati na platformi. Otvoreni smo za
							sve predloge, savete, ideje, kritike i sugestije, kako bi ovo postalo centralno, zajedničko mesto svih
							istraživača i onih koji to u ovom trenutku možda nisu, ali mogu imati koristi od ovakve platforme.
						</p>
					</div>

				</div>




			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "PlatformaSrpskiIstrazivaci",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {

		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
